const LinkType = {
  Any: "Any",
  Document: "Document",
  Media: "Media",
  Web: "Web"
};
export {
  LinkType
};


import { LinkType } from "../types/value/link.js";
import { asLink } from "../helpers/asLink.js";
import { escapeHTML } from "./escapeHTML.js";
const formatAttributes = (node, attributes) => {
  const _attributes = { ...attributes };
  if ("data" in node && "label" in node.data && node.data.label) {
    _attributes.class = _attributes.class ? `${_attributes.class} ${node.data.label}` : node.data.label;
  }
  const result = [];
  for (const key in _attributes) {
    const value = _attributes[key];
    if (value) {
      if (typeof value === "boolean") {
        result.push(key);
      } else {
        result.push(`${key}="${escapeHTML(value)}"`);
      }
    }
  }
  if (result.length) {
    result.unshift("");
  }
  return result.join(" ");
};
const getGeneralAttributes = (serializerOrShorthand) => {
  return serializerOrShorthand && typeof serializerOrShorthand !== "function" ? serializerOrShorthand : {};
};
const serializeStandardTag = (tag, serializerOrShorthand) => {
  const generalAttributes = getGeneralAttributes(serializerOrShorthand);
  return ({ node, children }) => {
    return `<${tag}${formatAttributes(node, generalAttributes)}>${children}</${tag}>`;
  };
};
const serializePreFormatted = (serializerOrShorthand) => {
  const generalAttributes = getGeneralAttributes(serializerOrShorthand);
  return ({ node }) => {
    return `<pre${formatAttributes(node, generalAttributes)}>${escapeHTML(node.text)}</pre>`;
  };
};
const serializeImage = (linkResolver, serializerOrShorthand) => {
  const generalAttributes = getGeneralAttributes(serializerOrShorthand);
  return ({ node }) => {
    const attributes = {
      ...generalAttributes,
      src: node.url,
      alt: node.alt,
      copyright: node.copyright
    };
    let imageTag = `<img${formatAttributes(node, attributes)} />`;
    if (node.linkTo) {
      imageTag = serializeHyperlink(linkResolver)({
        type: "hyperlink",
        node: {
          type: "hyperlink",
          data: node.linkTo,
          start: 0,
          end: 0
        },
        text: "",
        children: imageTag,
        key: ""
      });
    }
    return `<p class="block-img">${imageTag}</p>`;
  };
};
const serializeEmbed = (serializerOrShorthand) => {
  const generalAttributes = getGeneralAttributes(serializerOrShorthand);
  return ({ node }) => {
    const attributes = {
      ...generalAttributes,
      ["data-oembed"]: node.oembed.embed_url,
      ["data-oembed-type"]: node.oembed.type,
      ["data-oembed-provider"]: node.oembed.provider_name
    };
    return `<div${formatAttributes(node, attributes)}>${node.oembed.html}</div>`;
  };
};
const serializeHyperlink = (linkResolver, serializerOrShorthand) => {
  const generalAttributes = getGeneralAttributes(serializerOrShorthand);
  return ({ node, children }) => {
    const attributes = {
      ...generalAttributes
    };
    if (node.data.link_type === LinkType.Web) {
      attributes.href = node.data.url;
      attributes.target = node.data.target;
      attributes.rel = "noopener noreferrer";
    } else if (node.data.link_type === LinkType.Document) {
      attributes.href = asLink(node.data, { linkResolver });
    } else if (node.data.link_type === LinkType.Media) {
      attributes.href = node.data.url;
    }
    return `<a${formatAttributes(node, attributes)}>${children}</a>`;
  };
};
const serializeSpan = () => {
  return ({ text }) => {
    return text ? escapeHTML(text).replace(/\n/g, "<br />") : "";
  };
};
export {
  serializeEmbed,
  serializeHyperlink,
  serializeImage,
  serializePreFormatted,
  serializeSpan,
  serializeStandardTag
};


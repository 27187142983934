import { PrismicError } from "../errors/PrismicError.js";
const findRef = (refs, filter) => {
  const ref = refs.find((ref2) => filter(ref2));
  if (!ref) {
    throw new PrismicError("Ref could not be found.", void 0, void 0);
  }
  return ref;
};
export {
  findRef
};


const composeSerializers = (...serializers) => {
  return (...args) => {
    for (let i = 0; i < serializers.length; i++) {
      const serializer = serializers[i];
      if (serializer) {
        const res = serializer(...args);
        if (res != null) {
          return res;
        }
      }
    }
  };
};
export {
  composeSerializers
};


import { LinkType } from "../types/value/link.js";
import { documentToLinkField } from "./documentToLinkField.js";
const asLink = (linkFieldOrDocument, ...configObjectOrTuple) => {
  if (!linkFieldOrDocument) {
    return null;
  }
  const linkField = (
    // prettier-ignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Bug in TypeScript 4.9: https://github.com/microsoft/TypeScript/issues/51501
    // TODO: Remove the `prettier-ignore` comment when this bug is fixed.
    "link_type" in linkFieldOrDocument ? linkFieldOrDocument : documentToLinkField(linkFieldOrDocument)
  );
  const [configObjectOrLinkResolver] = configObjectOrTuple;
  let config;
  if (typeof configObjectOrLinkResolver === "function" || configObjectOrLinkResolver == null) {
    config = {
      linkResolver: configObjectOrLinkResolver
    };
  } else {
    config = { ...configObjectOrLinkResolver };
  }
  switch (linkField.link_type) {
    case LinkType.Media:
    case LinkType.Web:
      return "url" in linkField ? linkField.url : null;
    case LinkType.Document: {
      if ("id" in linkField && config.linkResolver) {
        const resolvedURL = config.linkResolver(linkField);
        if (resolvedURL != null) {
          return resolvedURL;
        }
      }
      if ("url" in linkField && linkField.url) {
        return linkField.url;
      }
      return null;
    }
    case LinkType.Any:
    default:
      return null;
  }
};
export {
  asLink
};


import { castArray } from "./castArray.js";
const appendFilters = (objWithFilters = {}, filters) => {
  return {
    ...objWithFilters,
    filters: [...objWithFilters.filters || [], ...castArray(filters)]
  };
};
export {
  appendFilters
};


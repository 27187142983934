import { RichTextNodeType } from "../types/value/richText.js";
const RichTextReversedNodeType = {
  [RichTextNodeType.listItem]: "listItem",
  [RichTextNodeType.oListItem]: "oListItem",
  [RichTextNodeType.list]: "list",
  [RichTextNodeType.oList]: "oList"
};
export {
  RichTextReversedNodeType
};


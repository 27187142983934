import { HTMLRichTextMapSerializer, RTLinkNode } from '@prismicio/client';

export const headingSerializer: HTMLRichTextMapSerializer = {
    heading1: ({ children }) => `<h1 class="heading">${children}</h1>`,
    heading2: ({ children }) => `<h2 class="heading">${children}</h2>`,
    heading3: ({ children }) => `<h3 class="heading">${children}</h3>`,
    paragraph: ({ children }) => `<p class="heading">${children}</p>`
};

export const textSizeMSerializer: HTMLRichTextMapSerializer = {
    heading1: ({ children }) => `<h1 class="text-size-m">${children}</h1>`,
    heading2: ({ children }) => `<h2 class="text-size-m">${children}</h2>`,
    heading3: ({ children }) => `<h3 class="text-size-m">${children}</h3>`,
    paragraph: ({ children }) => `<p class="text-size-m">${children}</p>`
};

export const textSizeLSerializer: HTMLRichTextMapSerializer = {
    heading1: ({ children }) => `<h1 class="text-size-l">${children}</h1>`,
    heading2: ({ children }) => `<h2 class="text-size-l">${children}</h2>`,
    heading3: ({ children }) => `<h3 class="text-size-l">${children}</h3>`,
    paragraph: ({ children }) => `<p class="text-size-l">${children}</p>`
};

export const textSizeXlSerializer: HTMLRichTextMapSerializer = {
    heading1: ({ children }) => `<h1 class="text-size-xl">${children}</h1>`,
    heading2: ({ children }) => `<h2 class="text-size-xl">${children}</h2>`,
    heading3: ({ children }) => `<h3 class="text-size-xl">${children}</h3>`,
    paragraph: ({ children }) => `<p class="text-size-xl">${children}</p>`
};

export const textSizeXlNoMarginSerializer: HTMLRichTextMapSerializer = {
    heading1: ({ children }) => `<h1 class="text-size-xl mb-0">${children}</h1>`,
    heading2: ({ children }) => `<h2 class="text-size-xl mb-0">${children}</h2>`,
    heading3: ({ children }) => `<h3 class="text-size-xl mb-0">${children}</h3>`,
    paragraph: ({ children }) => `<p class="text-size-xl mb-0">${children}</p>`
};

export const descriptionSerializer = (marginBottom = true, color?: string) => {
    return {
        list: ({ children }: any) => '<ul class="list-unstyled mt-5">' + children + '</ul>',
        listItem: ({ children }: any) => '<li class="mt-2 prismic-list-item-check">' + children + '</li>',
        hyperlink: ({ children, node }: any) => serializeHyperlink(children, node),
        paragraph: ({ children }: any) => `<p class="${!marginBottom ? 'mb-0' : ''} ${!color ? `color-${color}` : ''}">${children}</p>`
    };
};

export function serializeHyperlink(children: string, node: RTLinkNode, cssClass?: string, defaultColor: boolean = false) {
    let link;
    const { link_type, url } = node.data;
    switch (link_type) {
        case 'Document': {
            const { type, uid } = node.data;
            switch (type) {
                case 'standard-page':
                    link = `/${uid!.replace('startseite', '')}`;
                    break;
            }
            break;
        }
        default:
            link = url;
    }

    if (defaultColor) {
        return `<a href="${link}">${children}</a>`;
    }

    return `<a href="${link}" class="text-decoration-underline color-red ${cssClass}">${children}</a>`;
}

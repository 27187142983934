import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LinkField, SelectField } from '@prismicio/client';

@Component({
    selector: 'app-link',
    standalone: true,
    imports: [CommonModule, RouterLink, RouterLinkActive],
    templateUrl: './link.component.html',
    styleUrl: './link.component.scss'
})
export class LinkComponent {
    @Input() link?: LinkField | any;
    @Input() action?: SelectField;
    @Input() anker?: string;
    @Input() routerLinkActive: string = '';
    @Input() cssClass?: string;
    @Input() showArrow = false;
    @Input() whiteArrow = false;
}

import { castArray } from "./lib/castArray.js";
import { devMsg } from "./lib/devMsg.js";
const RENAMED_PARAMS = {
  accessToken: "access_token"
};
const castOrderingToString = (ordering) => {
  if (typeof ordering === "string") {
    if (process.env.NODE_ENV === "development") {
      const [field, direction] = ordering.split(" ");
      const objectForm = direction === "desc" ? `{ field: "${field}", direction: "desc" }` : `{ field: "${field}" }`;
      console.warn(`[@prismicio/client] A string value was provided to the \`orderings\` query parameter. Strings are deprecated. Please convert it to the object form: ${objectForm}. For more details, see ${devMsg("orderings-must-be-an-array-of-objects")}`);
    }
    return ordering;
  }
  return ordering.direction === "desc" ? `${ordering.field} desc` : ordering.field;
};
const buildQueryURL = (endpoint, args) => {
  const { filters, predicates, ...params } = args;
  const url = new URL(`documents/search`, `${endpoint}/`);
  if (filters) {
    if (process.env.NODE_ENV === "development" && !Array.isArray(filters)) {
      console.warn(`[@prismicio/client] A non-array value was provided to the \`filters\` query parameter (\`${filters}\`). Non-array values are deprecated. Please convert it to an array. For more details, see ${devMsg("filters-must-be-an-array")}`);
    }
    for (const filter of castArray(filters)) {
      url.searchParams.append("q", `[${filter}]`);
    }
  }
  if (predicates) {
    for (const predicate of castArray(predicates)) {
      url.searchParams.append("q", `[${predicate}]`);
    }
  }
  for (const k in params) {
    const name = RENAMED_PARAMS[k] || k;
    let value = params[k];
    if (name === "orderings") {
      const scopedValue = params[name];
      if (scopedValue != null) {
        if (process.env.NODE_ENV === "development" && typeof scopedValue === "string") {
          console.warn(`[@prismicio/client] A string value was provided to the \`orderings\` query parameter. Strings are deprecated. Please convert it to an array of objects. For more details, see ${devMsg("orderings-must-be-an-array-of-objects")}`);
        }
        const v = castArray(scopedValue).map((ordering) => castOrderingToString(ordering)).join(",");
        value = `[${v}]`;
      }
    } else if (name === "routes") {
      if (typeof params[name] === "object") {
        value = JSON.stringify(castArray(params[name]));
      }
    }
    if (value != null) {
      url.searchParams.set(name, castArray(value).join(","));
    }
  }
  return url.toString();
};
export {
  buildQueryURL
};


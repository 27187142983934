const isRepositoryEndpoint = (input) => {
  try {
    new URL(input);
    return true;
  } catch {
    return false;
  }
};
export {
  isRepositoryEndpoint
};


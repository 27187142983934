import { prismicClient } from '../prismic';
import { Environment } from './environment.model';

export const environment: Environment = {
    production: false,
    prismic: {
        client: prismicClient
    },
    title: 'Ingenieurbüro für Technische Ausrüstung'
};

import { serializeStandardTag, serializePreFormatted, serializeImage, serializeEmbed, serializeHyperlink, serializeSpan } from "../lib/serializerHelpers.js";
import { serialize } from "../richtext/serialize.js";
import { wrapMapSerializer } from "../richtext/wrapMapSerializer.js";
import { composeSerializers } from "../richtext/composeSerializers.js";
const createHTMLRichTextSerializer = (linkResolver, serializer) => {
  const useSerializerOrDefault = (nodeSerializerOrShorthand, defaultWithShorthand) => {
    if (typeof nodeSerializerOrShorthand === "function") {
      return (payload) => {
        return nodeSerializerOrShorthand(payload) || defaultWithShorthand(payload);
      };
    }
    return defaultWithShorthand;
  };
  const mapSerializer = {
    heading1: useSerializerOrDefault(serializer == null ? void 0 : serializer.heading1, serializeStandardTag("h1", serializer == null ? void 0 : serializer.heading1)),
    heading2: useSerializerOrDefault(serializer == null ? void 0 : serializer.heading2, serializeStandardTag("h2", serializer == null ? void 0 : serializer.heading2)),
    heading3: useSerializerOrDefault(serializer == null ? void 0 : serializer.heading3, serializeStandardTag("h3", serializer == null ? void 0 : serializer.heading3)),
    heading4: useSerializerOrDefault(serializer == null ? void 0 : serializer.heading4, serializeStandardTag("h4", serializer == null ? void 0 : serializer.heading4)),
    heading5: useSerializerOrDefault(serializer == null ? void 0 : serializer.heading5, serializeStandardTag("h5", serializer == null ? void 0 : serializer.heading5)),
    heading6: useSerializerOrDefault(serializer == null ? void 0 : serializer.heading6, serializeStandardTag("h6", serializer == null ? void 0 : serializer.heading6)),
    paragraph: useSerializerOrDefault(serializer == null ? void 0 : serializer.paragraph, serializeStandardTag("p", serializer == null ? void 0 : serializer.paragraph)),
    preformatted: useSerializerOrDefault(serializer == null ? void 0 : serializer.preformatted, serializePreFormatted(serializer == null ? void 0 : serializer.preformatted)),
    strong: useSerializerOrDefault(serializer == null ? void 0 : serializer.strong, serializeStandardTag("strong", serializer == null ? void 0 : serializer.strong)),
    em: useSerializerOrDefault(serializer == null ? void 0 : serializer.em, serializeStandardTag("em", serializer == null ? void 0 : serializer.em)),
    listItem: useSerializerOrDefault(serializer == null ? void 0 : serializer.listItem, serializeStandardTag("li", serializer == null ? void 0 : serializer.listItem)),
    oListItem: useSerializerOrDefault(serializer == null ? void 0 : serializer.oListItem, serializeStandardTag("li", serializer == null ? void 0 : serializer.oListItem)),
    list: useSerializerOrDefault(serializer == null ? void 0 : serializer.list, serializeStandardTag("ul", serializer == null ? void 0 : serializer.list)),
    oList: useSerializerOrDefault(serializer == null ? void 0 : serializer.oList, serializeStandardTag("ol", serializer == null ? void 0 : serializer.oList)),
    image: useSerializerOrDefault(serializer == null ? void 0 : serializer.image, serializeImage(linkResolver, serializer == null ? void 0 : serializer.image)),
    embed: useSerializerOrDefault(serializer == null ? void 0 : serializer.embed, serializeEmbed(serializer == null ? void 0 : serializer.embed)),
    hyperlink: useSerializerOrDefault(serializer == null ? void 0 : serializer.hyperlink, serializeHyperlink(linkResolver, serializer == null ? void 0 : serializer.hyperlink)),
    label: useSerializerOrDefault(serializer == null ? void 0 : serializer.label, serializeStandardTag("span", serializer == null ? void 0 : serializer.label)),
    span: useSerializerOrDefault(serializer == null ? void 0 : serializer.span, serializeSpan())
  };
  return wrapMapSerializerWithStringChildren(mapSerializer);
};
const wrapMapSerializerWithStringChildren = (mapSerializer) => {
  const modifiedMapSerializer = {};
  for (const tag in mapSerializer) {
    const tagSerializer = mapSerializer[tag];
    if (tagSerializer) {
      modifiedMapSerializer[tag] = (payload) => {
        return tagSerializer({
          ...payload,
          // @ts-expect-error - merging blockSerializer types causes TS to bail to a never type
          children: payload.children.join("")
        });
      };
    }
  }
  return wrapMapSerializer(modifiedMapSerializer);
};
const asHTML = (richTextField, ...configObjectOrTuple) => {
  if (richTextField) {
    const [configObjectOrLinkResolver, maybeSerializer] = configObjectOrTuple;
    let config;
    if (typeof configObjectOrLinkResolver === "function" || configObjectOrLinkResolver == null) {
      config = {
        linkResolver: configObjectOrLinkResolver,
        serializer: maybeSerializer
      };
    } else {
      config = { ...configObjectOrLinkResolver };
    }
    let serializer;
    if (config.serializer) {
      if (typeof config.serializer === "function") {
        serializer = composeSerializers((type, node, text, children, key) => (
          // TypeScript doesn't narrow the type correctly here since it is now in a callback function, so we have to cast it here.
          config.serializer(type, node, text, children.join(""), key)
        ), createHTMLRichTextSerializer(config.linkResolver));
      } else {
        serializer = createHTMLRichTextSerializer(config.linkResolver, config.serializer);
      }
    } else {
      serializer = createHTMLRichTextSerializer(config.linkResolver);
    }
    return serialize(richTextField, serializer).join("");
  } else {
    return null;
  }
};
export {
  asHTML
};


import { asTree } from "./asTree.js";
const serialize = (richTextField, serializer) => {
  return serializeTreeNodes(asTree(richTextField).children, serializer);
};
const serializeTreeNodes = (nodes, serializer) => {
  const serializedTreeNodes = [];
  for (let i = 0; i < nodes.length; i++) {
    const treeNode = nodes[i];
    const serializedTreeNode = serializer(treeNode.type, treeNode.node, treeNode.text, serializeTreeNodes(treeNode.children, serializer), treeNode.key);
    if (serializedTreeNode != null) {
      serializedTreeNodes.push(serializedTreeNode);
    }
  }
  return serializedTreeNodes;
};
export {
  serialize
};


import { PrismicError } from "./errors/PrismicError.js";
const getRepositoryName = (repositoryEndpoint) => {
  try {
    return new URL(repositoryEndpoint).hostname.split(".")[0];
  } catch {
    throw new PrismicError(`An invalid Prismic Rest API V2 endpoint was provided: ${repositoryEndpoint}`, void 0, void 0);
  }
};
export {
  getRepositoryName
};

